@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/Montserrat/Montserrat-Regular.ttf) format('woff');
}

// https://coolors.co/008ec8-74c61d-d32a2d-f78234-ffc800-8b8b8b-c2c2c2
:root {
  --jra-blue: #008EC8;
  --jra-blue-dark: #006e99;
  --light-blue: #CCE9F0;
  --green: #74C61D;
  --red: #D32A2D;
  --red-disabled: #d34c4e;
  --orange: #F78234;
  --yellow: #FFC800;
  --grey: #8B8B8B;
  --light-grey: #c2c2c2;
}

body {

  padding: 0rem;
  border: none;
  overflow-y: auto;
  max-width: 100%;
  max-height: 70vh;
  margin-bottom: 0rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error{
  color:var(--red)!important;
}

.css-1us79aj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  top: 0!important;
}


*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;

}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
  background-color: #99999973;
}

.hidden {
  display: none !important;
}

// Start main content container
.background {
  margin: 0;
  padding: 75px 15px 0rem;
  min-height: 100vh;
  background-color: #F7F7F7;

  @media screen and (max-width: 1200px) {
    padding: 145px 15px 0rem;
  }
  @media screen and (max-width: 950px) {
    padding: 160px 15px 0rem;
  }
  @media screen and (max-width: 800px) {
    padding: 200px 15px 0rem;
  }
  @media screen and (max-width: 500px) {
    padding: 310px 15px 0rem;
  }

  .backsquare {
    position: relative;
    max-width: 100%;
    height: auto;
    padding: 60px;
    padding-top: 35px;
    padding-bottom: 80px;
    overflow: hidden;
    background-color: #FFFFFF;
    border-radius: 30px;

    @media screen and (max-width: 550px) {
      padding: 10px 5vw;
    }

    h1 {
      font-size: 28px;
      font-weight: 600;

      @media screen and (max-width: 1500px) {
        font-size: 24px;
      }
    }

    .tableContainer {
      min-height: calc(100vh - 220px);
      height: calc(100vh - 220px);
      margin-bottom: 3rem;
      text-align: right;

      .addbutton {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 600px) {
        height: auto;
      }
    }

  }
}

// End main content container

//icon close popup
.close {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 15px;
  margin-top: 6px;
  margin-right: 10px;
  border-radius: 10px;

  svg {

    path:hover,
    path:focus {
      border-color: rgba(0, 0, 0, 0.55);
      box-shadow: rgba(0, 0, 5, 0.1) 0 6px 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    path:hover {
      transform: translateY(-2px);
    }

    path:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.55);
      box-shadow: rgba(0, 0, 5, 0.06) 0 3px 5px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    }
  }
}

//rewrite datagrid css
* {
  box-sizing: border-box;
}


h2 {
  font-size: 22px;
  font-weight: 600;
}

//Progress in datatable
.progress {
  position: relative;
  display: inline-flex;

  .progressItem {
    max-width: 30px;
    max-height: 30px;
    margin: auto;
  }

  .progressContainer {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}

// auto scroll

.autoScroll {
  padding-right: 5px !important;
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--light-grey);
    border-radius: 10px;
  }
}
#iframe{display: none;}

// select options

.MuiPaper-elevation {
  ul {
    li {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 800px) {
        font-size: 20px !important;
    }

      p {
        display: flex;
        flex-direction: column;
        margin: 0;

        @media screen and (max-width: 800px) {
            font-size: 20px !important;
        }
      
        span {
          font-size: 10px;

          @media screen and (max-width: 800px) {
            font-size: 15px !important;
        }
        }
      }

      img {
        height: 70px;
        width: auto;
      }
    }
  }
}

.MuiSelect-select {
  p {
    margin: 0;

    span {
      display: none;
    }
  }

  img {
    display: none;
  }
}

// DataGrid
@media screen and (max-width: 870px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(3),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(3) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 920px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(2),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(2) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 740px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(4),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(4) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

.MuiDataGrid-row {
  cursor: pointer;
  // width: 100%;

  .MuiDataGrid-cellContent {
    cursor: pointer;
  }
}

@media (max-width: 599.95px) {
  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root, .css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons{
     display: inline-flex!important;
  }
}


.tabsContainer{
  button{
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 40px;
    background-color: var(--jra-blue);
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    margin-right: 5px;
    border: 1px solid #ffffff;
    &:hover{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
    &.selected{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
  }
}