.content{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    font-style: italic;
    background-color: #F7F7F7;
    img{
        max-width: 100%;
    }
}