
.popperRoot {
  z-index: 3;

  .popperContent {
    margin-right: 0.8rem;
    border-radius: 25px!important;
    
    .iconUtile {
      align-items: center;
      padding-block: 0.5rem;
      max-height: 100%;
      overflow-y: auto;
      width: 21rem;
      height: 25rem;
      margin-top: 1.7rem;
    
      ul {
        padding-inline: 7%;
        margin:0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        overflow: hidden;
    
        li {
          display: flex;
          flex-direction: column;
          margin: 0;
          text-align: center;
  
          a {
            text-decoration: none;
          }
        
          .button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 6rem;
            min-height: 120px;
            text-decoration: none;
            margin: auto;
  
            img {
              width: 30px !important;
              height: 30px !important;
            }
        
            > div {
              width: 38px !important;
              height: 38px !important;
        
              svg {
                width: 28px !important;
                height: 28px !important;
              }
            }
  
            p {
              display: block;
              color: #585858;
              width: 54px;
              font-size: 11px !important;
              text-decoration: none;
              text-align: center;
              justify-content: center;
              font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis !important;
              margin-block: 5px;
          
              &:hover {
                white-space: normal;
                overflow: visible;
                text-overflow: clip; 
                word-wrap: break-word;
                text-decoration: none;
              }
            }  
          }
        }
      }
  
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 1px 4px rgba(80, 80, 80, 0.3);
        border-radius: 20px;
        background-color: #F5F5F5;
      }
      
      &::-webkit-scrollbar {
        width: 3px;
        background-color: #ffffff;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px rgba(75, 75, 75, 0.3);
        background-color: #c6c6c673;
      } 
    }
  }
}

  