  .StyledModal {
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Backdrop {
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(255, 255, 255);
    -webkit-tap-highlight-color: transparent;
  }

  .MuiBackdrop-open {
    background-color: rgb(255, 255, 255);
    -webkit-tap-highlight-color: transparent;
  }
  

  .box {
    max-height: auto;
    max-width: 900px;
    width: 60vw;
    margin: 0;
    padding-block: 0rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 10px;
    box-shadow: 0 8px 10px 1000px rgb(0 0 0 / 16%), 0 16px 24px 10px rgb(0 0 0 / 11%), 0 6px 30px 20px rgb(0 0 0 / 10%);

    .header {
      h2 {
        width: 80%;
        margin: 2vh auto;
      }
    }
  
    .respscroll{
      padding: 0rem;
      border: none;
      overflow-y: auto;
      max-width: 100%;
      max-height: 80vh;
      margin-bottom: 0rem;
    }
    .respscroll::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 1px 4px rgba(0,0,0,0.3);
      border-radius: 20px;
      background-color: #F5F5F5;
    }

    .respscroll::-webkit-scrollbar
    {
      width: 5px;
      background-color: #F5F5F5;
      
    }

    .respscroll::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
      background-color: #99999973;
      
    }
    .checkContenair {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 2em;

      .uiform {
        width: 50%;
      }
    }

    @media screen and (max-width:678px) {
      .checkContenair{
        flex-direction: column;
        .uiform {
          width: calc(100% - 4rem);
          
        }
      }
    }

    .buttonForm {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      height: 5rem;
      padding-bottom: 1.5rem;
      gap: 2rem;   
    }

    @media screen and (max-width:370px) {
      .buttonForm {
        gap: 5px;
      }
    }

    .buttonPNS {
      display: flex;
      justify-content: center;
      align-items: unset;
      text-align: center;

      span {
        margin-top: 1px;
      }
    }

  }

  @media screen and (max-width:1152px) {
    .box {
      width: 70vw;
    }
  }
  @media screen and (max-width:882px) {
    .box {
      width: 95vw;
    }
  }

.title {
  text-align: center;
  font-size: 24px;
}

.line {
    border-bottom: 1px solid black;
    width: 100%;
    padding-top: 1rem;
  }

.infoContent{
  width: 100%;
  p{
    margin: 0;
  }
}
