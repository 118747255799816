
#openWeather {
    @media screen and (max-width: 750px) {
        font-size: 12px;
    }
    @media screen and (max-width: 450px) {
        display: none;
    }

    .weatherWrapper {
        display: flex;
        gap: 13px;
        align-items: center;
        margin: 5px 0;
        padding: 5px 10px;
        border-radius: 10px;
        box-shadow: inset 0 0 3px 0 #999;
    
        p {
            margin: 0;

            &:first-of-type {
                display: flex;
                flex-direction: column;

                span {
                    &:first-of-type{
                        font-size: 14px;
                    }
                    &:nth-of-type(2){
                        font-size: 11px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .notifWrapper {
        gap: 0;

        &:hover {
            cursor: pointer;
        }

        p {
            font-size: 10px;
        }
    }
}