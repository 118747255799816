.uiform {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem 2rem 1rem 2rem;
    @media screen and (max-width: 650px) {
        padding: 1rem;
    }
    .contentText{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px 10px;
      width: 100%;
      align-items: center;
      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
      }
      .imgContainer {
        grid-column: 2;
        grid-row: 1/4;
        @media screen and (max-width: 650px) {
            grid-column: 1;
        }
        margin-inline: auto;
        width: 100%;
        height: 140px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 1%;
        cursor: pointer;
        position: relative;
        border:solid rgba(0, 0, 0, 0.23) 1px;
        &:hover {
            opacity: 0.8;
            border: solid black 1px;
        }
        &::after { 
            content: "Cliquez pour ajouter une photo"; 
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            position: absolute; 
            color: #919191;
            top: 0; 
            left: 0; 
            z-index: -1; 
            padding: 8.5px 14px;
        } 
        svg {
            border: 10px solid #000000;
            box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            width: 1.5rem;
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            background-color: white;
            border: #181a1d;
            padding: 4px;
            border-radius: 50%;
        }
      }
      :last-child{
        grid-column: 1 / span 2;
        @media screen and (max-width: 650px) {
          grid-column: 1;
        }
      }
    }
    .checkContenair{
        display: flex;
        flex-direction: row;
        width: 100%;
        .uicheckbox {
            margin-left: 5px;
            width: 50%;
            padding: 8.5px 14px;
            @media screen and (max-width: 650px) {
              width: 100%;
            }
        }
    }
    .textAreaContenair{
      width: 50%;
      margin-right: 5px;
      textarea{
        width: 100%;    
        min-height: 22rem;
        max-height: 22rem;
        background-color: rgb(255, 255, 255);
        border: 2px solid #e1e4e8;
        border-radius: 8px;
        font-family: "Roboto", "Helvetica";
        padding:8.5px 14px;
      }
    }
  }

  .scrollDiv{
    max-height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #F5F5F5;
    
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
      background-color: #99999973;
    }
  }
  .fileLink{
    align-content: center;
  }
  
.tabsContainer{
  button{
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 40px;
    background-color: var(--jra-blue);
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    margin-right: 5px;
    border: 1px solid #ffffff;
    &:hover{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
    &.selected{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
  }
}
.historyComment{
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    background-color: #99999973;
  }
  .historyContent{
    align-self: flex-start;
    margin: 15px 0;
    width: 50%;
    span{
      margin-bottom: 10px;
    }
  }
  .historyContent:nth-child(2n){
    align-self: flex-end;
  }
}
