
.popperRoot {
  z-index: 3;
  
}

.dropdownMenu {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;

}
    .username {
      p {
          margin-left: 1.3rem;
          font-size: 15px;
      }
    }
      
    .menuItem {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;
      transition: color 0.3s;
      span {
        margin-left: 5px;
      }


      &.active {
        color: #008EC8;
      }
    }

    .menuItem {
      display: flex;
      align-items: center;
      .menuLink {
        color: #6d6d6d;
        text-decoration: none;
        align-items: center;
        display: flex;
        margin-block: 5px;
      }
    
      .MuiListItemIcon-root {
        min-width: auto;
      }

      svg {
        fill: #6d6d6d;
        align-items: center;
      }
      
      .menuLink {
        color: #6d6d6d;
        text-decoration: none;
      }
    
      &.active {
        svg {
          fill: #008EC8;
        }
        .menuLink {
          color: #008EC8;
          font-weight: 700 !important;
        }
      }

      &:hover{
        svg {
          fill: #008EC8;
        }
        .menuLink {
          color: #008EC8;
          font-weight: 700 !important;
        }
      }

    }

    
    .lienUtile {
      margin-top: 0.5rem;

      @media screen and (max-width: 650px) {
        margin-top: 4px;
      }

      svg {
        @media screen and (max-width: 650px) {
          width: 35px;
          height: 35px;
        }
      }
    }

    .divider {
      margin-inline-start: 1rem;
      height: 45px;
      align-self: center;
    }




    // svg {
    //   fill: #6d6d6d;
    //   height:30px;
    //   width: 30px;
    //   @media screen and (max-width: 650px) {
    //     height: 40px;
    //     width: 40px;
    //   }
    // }
    
    // a {
    //   width: 100%;
    //   color: #6d6d6d;
    //   text-decoration: none;
    // }
  
    // &.active {
    //   svg {
    //     fill: #008EC8;
    //   }
    //   a {
    //     color: #008EC8;
    //     font-weight: 700;
    //   }
    // }

    // &:hover{
    //   svg {
    //     fill: #008EC8;
    //   }
    //   a {
    //     color: #008EC8;
    //     font-weight: 700;
    //   }
    // }
  
